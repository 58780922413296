import React from 'react'
import clsx from 'clsx'
import { IconButton } from '~ui'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

const scrollSpace = 5

export const ScrollableHorizontalBox: React.FC<
  React.PropsWithChildren<{ className?: string; hideScrollbar?: boolean }>
> = ({ className, children, hideScrollbar = false }) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [scrolling, setScrolling] = React.useState<'left' | 'right' | 'none'>(
    'none',
  )
  const handleStop = React.useCallback(() => {
    setScrolling('none')
  }, [])
  const handleHoverLeft = React.useCallback(() => {
    setScrolling('left')
  }, [])
  const handleHoverRight = React.useCallback(() => {
    setScrolling('right')
  }, [])
  const handleClickLeft = React.useCallback(() => {
    if (ref.current) {
      setScrolling('none')
      const currentScroll = ref.current.scrollLeft
      const width = ref.current.offsetWidth

      ref.current.scrollTo({
        left: currentScroll - width,
        behavior: 'smooth',
      })
    }
  }, [])
  const handleClickRight = React.useCallback(() => {
    if (ref.current) {
      setScrolling('none')
      const currentScroll = ref.current.scrollLeft
      const width = ref.current.offsetWidth

      ref.current.scrollTo({
        left: currentScroll + width,
        behavior: 'smooth',
      })
    }
  }, [])

  React.useEffect(() => {
    let requestId: number | null = null
    const scroll = () => {
      if (ref.current && scrolling !== 'none') {
        const currentScroll = ref.current.scrollLeft

        ref.current.scrollTo({
          left:
            scrolling === 'left'
              ? currentScroll - scrollSpace
              : currentScroll + scrollSpace,
          // can't be smooth
          // behavior: 'smooth',
        })
        requestId = requestAnimationFrame(scroll)
      }
    }
    scroll()

    return () => {
      if (requestId !== null) {
        cancelAnimationFrame(requestId)
      }
    }
  }, [ref, scrolling])
  return (
    <div className="relative">
      <div
        ref={ref}
        className={clsx(
          'flex flex-grow flex-nowrap gap-2 overflow-x-auto min-w-0',
          // 'max-w-xs md:max-w-md lg:max-w-lg xl:max-w-4xl',
          hideScrollbar ? 'overflow-x-hidden' : '',
          className,
        )}
      >
        {children}
      </div>
      <IconButton
        type="button"
        className="absolute left-1 top-1/2 -mt-8 z-5 p-1 rounded-full bg-white bg-opacity-70 text-gray-400 hover:text-gray-800 hover:bg-opacity-80 focus:outline-none"
        icon={<ChevronLeftIcon className="w-8 h-8" aria-hidden="true" />}
        onMouseEnter={handleHoverLeft}
        onMouseLeave={handleStop}
        onClick={handleClickLeft}
      >
        <span className="sr-only">Move left</span>
      </IconButton>
      <IconButton
        type="button"
        className="absolute right-1 top-1/2 -mt-8 z-5 p-1 rounded-full bg-white bg-opacity-70 text-gray-400 hover:text-gray-800 hover:bg-opacity-80 focus:outline-none"
        icon={<ChevronRightIcon className="w-8 w-8" aria-hidden="true" />}
        onMouseEnter={handleHoverRight}
        onMouseLeave={handleStop}
        onClick={handleClickRight}
      >
        <span className="sr-only">Move right</span>
      </IconButton>
    </div>
  )
}
